/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-heic': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zm-4.637 8.554a1.7 1.7 0 00-.103.633v.495q0 .369.103.627a.83.83 0 00.299.393.85.85 0 00.477.131.9.9 0 00.401-.088.7.7 0 00.273-.248.8.8 0 00.117-.364h.765v.076a1.27 1.27 0 01-.226.674q-.205.29-.55.454a1.8 1.8 0 01-.786.164q-.54 0-.914-.216a1.4 1.4 0 01-.571-.627q-.194-.408-.194-.976v-.498q0-.568.197-.978.196-.411.571-.633.378-.223.911-.223.328 0 .607.097.28.093.489.272a1.32 1.32 0 01.466.964v.073h-.765a.85.85 0 00-.12-.38.7.7 0 00-.273-.261.8.8 0 00-.398-.097.8.8 0 00-.475.138.87.87 0 00-.301.398m-6.1-1.128v4h-.79V14.21H.79v1.714H0v-3.999h.791v1.626h1.682v-1.626zm1.488 3.352h1.79v.647H3.966v-3.999H6.54v.648H4.75v1.025h1.684v.607H4.751zm3.163.647v-3.999h-.791v4h.79z"/>',
    },
});
